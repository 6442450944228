<template>
  <section id="venueOverview">
			<div class="mobile-subnav">
				<SideBarNavMobile subsectionTitle="Venue Overview" />
			</div>
      <div class="venueOverview-cnt">
          <div class="venue-title-cnt">
            <div class="venue-title">Venue Overview</div>
            <div class="line-divider"></div>
          </div>
          <div class="venue-content">
              <div class="overviewBox">
                  <div class="img-cnt">
                    <img class="person" src="@/assets/images/VenueOverview/person-icon.svg" />
                  </div>
                  <div class="overview-desc">Over 10 event spaces ranging in capacities from 25 to 65,000</div>
              </div>
              <div class="overviewBox">
                  <div class="img-cnt">
                    <img class="one-mile" src="@/assets/images/VenueOverview/1-mile-icon.svg" />
                  </div>
                  <div class="overview-desc">Located Less than 1 Mile from the Las Vegas Strip</div>
              </div>
              <div class="overviewBox">
                  <div class="img-cnt">
                    <img class="diamond" src="@/assets/images/VenueOverview/diamond-icon.svg" />
                  </div>
                  <div class="overview-desc">Experienced and professional Sales, Event Management, and Catering Team to provide unparalleled service from planning to execution</div>
              </div>
              <div class="overviewBox">
                  <div class="img-cnt">
                    <img class="opening" src="@/assets/images/VenueOverview/tech-icon.svg" />
                  </div>
                  <div class="overview-desc">State of the art technological capabilities and in-house assets to take any event to the next level</div>
              </div>
              <div class="overviewBox">
                  <div class="img-cnt">
                    <img class="tech" src="@/assets/images/VenueOverview/open-icon.svg" />
                  </div>
                  <div class="overview-desc">Opened August 2020</div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import SideBarNavMobile from '@/components/Nav/SideBarNavMobile'

export default {
	name: "VenueOverview",
	components: {
		SideBarNavMobile
	}
};
</script>

<style lang="scss" scoped>
#venueOverview {
    width: 100vw;
    height: 100vh;
    background: url('~@/assets/images/VenueOverview/bg-venueOverview.jpg') scroll no-repeat center center;
    background-size: cover;
    padding: 160px 0 160px 268px;
		@media (max-width: $lg) {
			padding: 0;
			height: auto;
			background: url('~@/assets/images/MobileBG/Venue-Overview-BG.jpg') fixed no-repeat center center;
			background-size: cover;
		}
    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(0,0,0,0.85) 51%, rgba(0,0,0,0.02) 80%);
				@media (max-width: $lg) {
					background-image: none;
				}
    }
    .venueOverview-cnt {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        margin-left: 120px;
				@media (max-width: $lg) {
					padding: 170px 15px 0 15px;
					margin-left: 0;
				}
        .venue-title-cnt {
            display: inline-flex;
            flex-wrap: wrap;
            align-self: flex-start;
            margin-bottom: 50px;;
            .venue-title {
                width: 100%;
                margin-bottom: 25px;
                font-size: 35px;
                font-family: 'ArtegraSans-Bold';
                line-height: 30px;
                letter-spacing: 2.19px;
                text-transform: uppercase;
                color: #FFFFFF;
            }
            .line-divider {
                position: relative;
                width: 100%;
                height: 2px;
                background: #FFFFFF;
                box-shadow: 0 0 9px 2px #FFFFFF;
            }
        }
        .venue-content {
            .overviewBox {
                display: flex;
                flex-direction: row;
                padding: 10px 0;
                &:first-child {
                    padding: 0 0 10px 0;
                }
                .img-cnt {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 58px;
                    margin-right: 22px;;
                    img {
                        width: 100%;
                        max-width: none;
                        &.one-mile {
                            width: 78px;
                        }
                        &.diamond {
                            width: 105px;
                            margin-left: -32.5px;
                        }
                        &.opening {
                            width: 74px;
                        }
                    }
                }
                .overview-desc {
                    display: flex;
                    align-items: center;
                    max-width: 434px;
                    font-size: 16px;
                    font-family: 'ArtegraSans-Regular';
                    line-height: 23px;
                    letter-spacing: 1.07px;
                    color: #FFFFFF;
                }
            }
        }
    }
		.mobile-subnav {
			display: none;
			@media (max-width: $lg) {
				display: block;
			}
		}
}
</style>